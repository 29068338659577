import { Injectable } from '@angular/core';

import { Observable, catchError, of, mergeMap, concat, EMPTY  } from 'rxjs';

import {  
  FundResponse,
  GetListResponseFundResponse,
  DataModelPydanticModelsCommonLedgerResponse,
  FundDocumentType,
  CommonFundOperationsService,
  FundExternalIDsService,
  FundDocumentsService, 
} from '@root/api/idlt';

import {
  DEFAULT_SORT_BY,
  DEFAULT_SORT_DESCENDING,
} from '@core/config';
import { environment } from '@root/environments/environment';


export interface FundsMap {
    [key: string]: FundResponse;
}

export interface GetFundsResponse extends GetListResponseFundResponse {
  fundsByid: FundsMap | null;
}

@Injectable({
  providedIn: 'root'
})
export class FundsApiService {
  constructor(
    private readonly fundsService: CommonFundOperationsService, 
    private readonly fundExternalIDsService: FundExternalIDsService, 
    private readonly fundDocumentsService: FundDocumentsService
  ) { }

  createFundsByIdMap(funds: FundResponse[]): FundsMap {
    const map = {};
    funds.forEach(fund => {
      map[fund.id] = fund;
    })
    return map;
  }

  getFunds(pageSize, page): Observable<any> {
    return this.fundsService.listFundsV1FundsGet(
      pageSize, 
      page, 
      'info.name', 
      DEFAULT_SORT_DESCENDING, undefined, undefined, undefined, undefined, undefined, 
      undefined, undefined, undefined, undefined, environment.idlt.detokenizeResults
    ).pipe(
      catchError(() => of(null)),
      mergeMap((res: GetListResponseFundResponse | null) => {
        if(!res) return of(null);
        const current$ = of({
          pagesRemaining: res.pagesRemaining,
          funds: res.results,
          fundsById: this.createFundsByIdMap(res.results),
        });

        let next$

        if(res.pagesRemaining && res.pagesRemaining > 0) {
          next$ = this.getFunds(pageSize, res.page ? res.page+1:1);
        } else {
          next$ = EMPTY;
        }

        return concat(current$, next$);
      })
    )
  }

  updateExternalId(fundId, body): Observable<DataModelPydanticModelsCommonLedgerResponse | null> {
    return this.fundExternalIDsService.upsertFundExternalIdV1FundsFundIdExternalIdPost(fundId, body)
      .pipe(
        catchError(() => {
          return of(null)
        }),
      );
  }

  deleteExternalId(fundId, externalIdType): Observable<DataModelPydanticModelsCommonLedgerResponse | null> {
    return this.fundExternalIDsService
      .removeFundExternalIdV1FundsFundIdExternalIdDelete(
        fundId, 
        externalIdType, 
        undefined, 
        undefined, 
        undefined, 
        undefined, 
        true
      )
      .pipe(
        catchError(() => {
          return of(null)
        })
      )
  }

  getFundById(fundId: string): Observable<FundResponse | null> {
    return this.fundsService.getFundV1FundsFundIdGet(fundId)
      .pipe(catchError(() => of(null)))
  }

  downloadDocument(fundId: string, fileType: FundDocumentType) {
    return this.fundDocumentsService
      .downloadFundDocumentV1FundsFundIdDocumentsDocumentTypeGet(fundId, fileType)
  }
}
